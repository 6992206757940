// @ts-nocheck
console.log("HELLO");

const test = () => {
  console.log("this is a test");
};

const toggle = document.getElementById("toggle");
toggle.onclick = function () {
  toggle.classList.toggle("navbar__hamburger__active");
};

const navHamburger = document.querySelector(".navbar__hamburger");
const navigation = document.querySelector(".navigation");
// const navLink1 = document.getElementById("linkmen1");
// const navLink2 = document.getElementById("linkmen2");
// const navLink3 = document.getElementById("linkmen3");

navHamburger.addEventListener("click", () => {
  navigation.classList.toggle("navigation__active");
});

// navHamburger.addEventListener("click", () => {
//   navLink1.classList.toggle("display_none");
// });

// navHamburger.addEventListener("click", () => {
//   navLink2.classList.toggle("display_none");
// });

// navHamburger.addEventListener("click", () => {
//   navLink3.classList.toggle("display_none");
// });

// const cursor = document.querySelector(".cursor");
// document.addEventListener("mousemove", (e) => {
//   cursor.setAttribute("style", "top: " + (e.pageY - 15) + "px; left: " + (e.pageX - 15) + "px;");
// });

// document.addEventListener("click", () => {
//   cursor.classList.add("expand");

//   setTimeout(() => {
//     cursor.classList.remove("expand");
//   }, 500);
// });
